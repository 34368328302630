var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-button',{staticStyle:{"margin-bottom":"10px","margin-right":"20px"},attrs:{"type":"primary","size":"mini","icon":"el-icon-back"},on:{"click":function($event){return _vm.go_look(1)}}},[_vm._v("返回")]),_c('el-card',{staticClass:"box-card"},_vm._l((_vm.data),function(formLabelAlign,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"20px"}},[_c('el-card',{staticClass:"box-card"},[_c('h1',{staticClass:"vintage"},[_vm._v(_vm._s(_vm.options[index].label))]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"details"},[_c('el-form',{attrs:{"label-position":"right","label-width":"120px","model":formLabelAlign}},[_c('div',{staticClass:"el-form-item"},[_c('label',{staticClass:"el-form-item__label",staticStyle:{"width":"120px"}},[_vm._v("备选项：")]),_c('div',{staticClass:"el-form-item__content",staticStyle:{"margin-left":"120px"}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((formLabelAlign.topicOptions),function(topicOptions,topicOptionsindex){return _c('div',{key:topicOptionsindex,staticClass:"topicOptions",style:(topicOptions.isAnswer == 1
                          ? 'color: rgb(0, 155, 28) !important;  background-color: #EFFFF2; '
                          : '  background-color: #fff;')},[_vm._v(" "+_vm._s(topicOptions.sn)+" "),_c('span',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(topicOptions.choiceContent)}})])}),0)])]),_c('div',{staticClass:"el-form-item",staticStyle:{"color":"rgb(0, 155, 28) !important"}},[_c('label',{staticClass:"el-form-item__label",staticStyle:{"color":"rgb(0, 155, 28) !important","width":"120px"}},[_vm._v("正确答案：")]),_c('div',{staticClass:"el-form-item__content",staticStyle:{"margin-left":"120px"}},[_vm._v(" "+_vm._s(formLabelAlign.topicAnswer)+" ")])]),_c('el-form-item',{attrs:{"label":"知识点名称："}},[_c('p',{domProps:{"innerHTML":_vm._s(formLabelAlign.knowledgeDot)}})]),_c('el-form-item',{attrs:{"label":"做题次数："}},[_vm._v(_vm._s(formLabelAlign.topicCount))]),_c('el-form-item',{attrs:{"label":"答题时间："}},[_vm._v(_vm._s(formLabelAlign.topicTime))]),_c('el-form-item',{attrs:{"label":"题目解析："}},[_c('p',{domProps:{"innerHTML":_vm._s(formLabelAlign.caseAnalysis)}})])],1)],1)]),_c('p',{staticClass:"title title-a",staticStyle:{"margin-top":"30px"}},[_vm._v(" 练习日志 "),_c('i',{staticStyle:{"padding-left":"10px","padding-right":"3px"}},[_c('i',{staticStyle:{"width":"10px","height":"10px","border-radius":"50%","border":"1px solid #009b1c","display":"inline-block"}}),_vm._v(" 正确 ")]),_c('i',{staticStyle:{"padding-left":"10px","padding-right":"3px"}},[_c('i',{staticStyle:{"width":"10px","height":"10px","border-radius":"50%","border":"2px solid #d00303","display":"inline-block"}}),_vm._v(" 错误 ")])]),_c('div',{staticClass:"journal"},[_c('el-scrollbar',[_c('el-table',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"data":formLabelAlign.topicLog,"header-cell-style":{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                fontWeight: '500',
              },"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"prop":"time","label":"答题时间","width":"200"}}),_c('el-table-column',{attrs:{"prop":"option","label":"我的回答","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" 此次选择答案为 "+_vm._s(scope.row.option)+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"正确答案","align":"left","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(formLabelAlign.topicAnswer)+" ")]}}],null,true)})],1)],1)],1)]),(index == _vm.data.length - 1)?_c('el-divider'):_vm._e(),(index == _vm.data.length - 1)?_c('el-card',{staticClass:"box-card"},[(index == _vm.data.length - 1)?_c('p',{staticClass:"title title-a"},[_vm._v(" 溯源《找标准答案》点击日志 ")]):_vm._e(),(index == _vm.data.length - 1)?_c('div',{staticClass:"journal"},[_c('el-scrollbar',{},[_c('el-table',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"data":formLabelAlign.knowledgeLogDOS,"header-cell-style":{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                fontWeight: '500',
              }}},[_c('el-table-column',{attrs:{"prop":"startSecond","label":"开始时间"}}),_c('el-table-column',{attrs:{"label":"操作记录","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.startName ? scope.row.startName : "")+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"endSecond","label":"结束时间","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作记录","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(scope.row.endName ? scope.row.endName : ""))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"","label":"停留时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.time ? scope.row.time / 1000 + "秒" : "--")+" ")])]}}],null,true)})],1)],1)],1):_vm._e()]):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }