<!-- 成人答题总结  adultSummary  -->
<template>
  <div style>
    <el-button
      type="primary"
      size="mini"
      icon="el-icon-back"
      @click="go_look(1)"
      style="margin-bottom: 10px; margin-right: 20px"
      >返回</el-button
    >
    <el-card class="box-card">
      <!-- <el-select
        size="mini"
        v-model="value"
        placeholder="请选择"
        @change="changeSelect"
        style="margin-bottom: 10px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <p v-html="item.label"></p>
        </el-option>
      </el-select>-->
      <div
        v-for="(formLabelAlign, index) in data"
        :key="index"
        style="margin-bottom: 20px"
      >
        <el-card class="box-card">
          <!-- <el-divider></el-divider> -->
          <h1 class="vintage">{{ options[index].label }}</h1>
          <div class="box">
            <!-- <el-scrollbar style> -->

            <div class="details">
              <!-- <el-scrollbar style="height: 100%"> -->
              <el-form
                label-position="right"
                label-width="120px"
                :model="formLabelAlign"
              >
                <div class="el-form-item" style="">
                  <label style="width: 120px" class="el-form-item__label"
                    >备选项：</label
                  >
                  <div class="el-form-item__content" style="margin-left: 120px">
                    <div style="display: flex; flex-wrap: wrap">
                      <div
                        v-for="(
                          topicOptions, topicOptionsindex
                        ) in formLabelAlign.topicOptions"
                        :key="topicOptionsindex"
                        class="topicOptions"
                        :style="
                          topicOptions.isAnswer == 1
                            ? 'color: rgb(0, 155, 28) !important;  background-color: #EFFFF2; '
                            : '  background-color: #fff;'
                        "
                      >
                        {{ topicOptions.sn }}
                        <span
                          v-html="topicOptions.choiceContent"
                          style="display: inline-block"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="el-form-item"
                  style="color: rgb(0, 155, 28) !important"
                >
                  <label
                    style="color: rgb(0, 155, 28) !important; width: 120px"
                    class="el-form-item__label"
                    >正确答案：</label
                  >
                  <div class="el-form-item__content" style="margin-left: 120px">
                    {{ formLabelAlign.topicAnswer }}
                  </div>
                </div>
                <el-form-item label="知识点名称：">
                  <p v-html="formLabelAlign.knowledgeDot"></p>
                </el-form-item>
                <!-- <el-form-item label="练习目的：">
                  <p v-html="'a'"></p
                  ></el-form-item>-->
                <el-form-item label="做题次数：">{{
                  formLabelAlign.topicCount
                }}</el-form-item>

                <!-- <el-form-item
                      label="正确答案："
                      style="color: #009b1c !important"
                      >{{ formLabelAlign.topicAnswer }}</el-form-item
                    > -->
                <el-form-item label="答题时间：">{{
                  formLabelAlign.topicTime
                }}</el-form-item>
                <el-form-item label="题目解析：">
                  <p v-html="formLabelAlign.caseAnalysis"></p>
                </el-form-item>
              </el-form>
              <!-- </el-scrollbar> -->
            </div>
            <!-- </el-scrollbar> -->
          </div>
          <p class="title title-a" style="margin-top: 30px">
            练习日志
            <i style="padding-left: 10px; padding-right: 3px">
              <i
                style="
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  border: 1px solid #009b1c;
                  display: inline-block;
                "
              ></i>
              正确
            </i>
            <i style="padding-left: 10px; padding-right: 3px">
              <i
                style="
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  border: 2px solid #d00303;
                  display: inline-block;
                "
              ></i>
              错误
            </i>
          </p>
          <div class="journal">
            <el-scrollbar>
              <el-table
                :data="formLabelAlign.topicLog"
                style="width: 100%; margin-bottom: 10px"
                :header-cell-style="{
                  background: '#F3F7FF',
                  color: '#0B1B31',
                  fontSize: '14px',
                  fontWeight: '500',
                }"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  prop="time"
                  label="答题时间"
                  width="200"
                ></el-table-column>
                <el-table-column prop="option" label="我的回答" align="center">
                  <template slot-scope="scope">
                    <div>
                      此次选择答案为
                      {{ scope.row.option }}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="结果分析" align="right">
                  <template slot-scope="scope">
                    <div
                      :class="scope.row.result == '√' ? 'result' : 'result-a'"
                    >
                      {{ scope.row.result }}
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="正确答案" align="left" width="200">
                  <template slot-scope="scope">
                    {{ formLabelAlign.topicAnswer }}
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-card>
        <el-divider v-if="index == data.length - 1"></el-divider>
        <el-card class="box-card" v-if="index == data.length - 1">
          <p class="title title-a" v-if="index == data.length - 1">
            溯源《找标准答案》点击日志
          </p>
          <div class="journal" v-if="index == data.length - 1">
            <el-scrollbar style>
              <el-table
                :data="formLabelAlign.knowledgeLogDOS"
                style="width: 100%; margin-bottom: 10px"
                :header-cell-style="{
                  background: '#F3F7FF',
                  color: '#0B1B31',
                  fontSize: '14px',
                  fontWeight: '500',
                }"
              >
                <el-table-column
                  prop="startSecond"
                  label="开始时间"
                ></el-table-column>
                <el-table-column label="操作记录" align="center">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.startName ? scope.row.startName : "" }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="endSecond"
                  label="结束时间"
                  align="center"
                ></el-table-column>
                <el-table-column label="操作记录" align="center">
                  <template slot-scope="scope">
                    <div>{{ scope.row.endName ? scope.row.endName : "" }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop label="停留时间" align="center">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.time ? scope.row.time / 1000 + "秒" : "--" }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import { themeStudentGetCaseResult } from "../../../api/api";
export default {
  data() {
    return {
      data: [],
      options: [],
      index: 99,
      value: "",
      formLabelAlign: {},
      tableData: [],
      obj: null,
    };
  },

  components: {},
  mounted() {
    this.init();
  },
  watch: {
    index(newName, oldName) {
      this.formLabelAlign = this.data[newName];
    },
  },
  methods: {
    init() {
      this.obj = JSON.parse(this.$route.query.data);

      themeStudentGetCaseResult({
        id: this.obj.caseId,
        activityId: this.obj.activityId,
      }).then((res) => {
        this.data = res.data[0].topicReturnDO;
        if (this.data.length > 0) {
          this.data.map((item, index) => {
            item.topicTime = this.currentTime.formatDate(item.topicTime * 1000);
            if (item.topicLog.length > 0) {
              item.topicLog.map((i) => {
                i.time = this.currentTime.formatDate(i.time * 1000);
                if (i.roW == 2) {
                  i["result"] = "×";
                } else {
                  i["result"] = "√";
                }
              });
            }
            if (item.knowledgeLogDOS != null) {
              item.knowledgeLogDOS.map((k) => {
                k.startSecond = this.currentTime.formatDate(
                  k.startSecond * 1000
                );
                k.endSecond = this.currentTime.formatDate(k.endSecond * 1000);
              });
            }

            this.options.push({
              value: index,
              label: item.caseTopicSerial,
            });
          });
        }

        this.index = 0;
        this.value = this.data[this.index].caseTopicSerial;
      });
    },
    style() {},
    //切换变式小题
    changeSelect(index) {
      this.index = index;
    },
    go_look(type) {
      if (type == 1) {
        this.$router.go(-1);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.result == "√") {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
  },
};
</script>
<style>
.el-table .warning-row {
  background: #effff2 !important;
}
.el-table .success-row {
  background: #fef3f3;
}
</style>
<style lang='less' scoped>
@import "./style/index.less";
/deep/.el-table .warning-row {
  background: #effff2 !important;
}

/deep/.el-table .success-row {
  background: #fef3f3;
}
</style>
